import './form'
import './popup'
import './animation'


$('.js-load-more').click(function (e) {
	e.preventDefault();
	let button = $(this);
	let paged = button.attr("data-paged");
	let max  = button.attr("data-max");	
	let posttype  = button.attr("data-posttype");
	let resultdiv  = button.attr("data-resultdiv");
	let postcount  = button.attr("data-postcount");
	let data={
		'max': max,
		'paged': paged,		
		'posttype': posttype,	
		'postcount': postcount,		
		'action': 'load_more_product'
	};
	$.ajax({
		type: 'POST',
		url: '/wp-admin/admin-ajax.php',
		data: data,
		error: function (jqXHR, exception) {
		},
		beforeSend : function ( xhr ) {		
			button.text('Загружается...'); 
		},
		success : function( data ){
			button.html('<span> Загрузить еще </span>');
			console.log(data);
			paged++;

			if (paged == max) {
				button.hide();
			}
			if (data) {
				jQuery('.'+resultdiv).append(data);
				button.html('<span> Загрузить еще </span>');
				button.attr('data-paged', paged);
				console.log(data);
	
				
			} else {
				button.hide();
			}
		}
	});
});

$('.js-nav-btn').on('click',function(){
	var $this = $(this);
	var navBlock = $('.js-nav-block');
	$this.toggleClass('burger_active');
	navBlock.slideToggle();
	$('.menu-wrap').toggleClass('active'); 
});

$('.scrollbar-inner').scrollbar();


// Если на главной то скролим
$(".menu-wrap--home .anchor a").click(function(e){
  e.preventDefault(); 
  var anchor = $(this).attr("href").substring($(this).attr("href").lastIndexOf('/') + 1);
  console.log(anchor);
  $("html, body").animate({
    scrollTop: ($(anchor).offset().top-100) + "px"
  });

	if($(window).width()<992 ){
		$('.menu-wrap').removeClass('active'); 
		$('.js-nav-btn').removeClass('burger_active');
	}

});


var myHash = location.hash; //получаем значение хеша
location.hash = ''; //очищаем хеш
if(myHash[1] != undefined){ //проверяем, есть ли в хеше какое-то значение
	$('html, body').animate({scrollTop: $(myHash).offset().top-100}, 500); //скроллим за полсекунды
};


$('.about-item').on("click",function(e){
	e.preventDefault();
	$('.about-item').removeClass("active");
	$(this).addClass("active");
	let tab=$(this).data('tab');
	$('.about-item__info').removeClass('active');
	$('.about-item__info[data-tab='+tab+']').addClass('active');
});

$(document).on("click",".about-item__info.active .about-item__btn",function(e) {
	e.preventDefault();
	$('.about-item__info.active .about-item__btn').removeClass("active");
	$(this).addClass("active");
	let tab=$(this).data('tab');
	$('.about-item__info.active .about-item__tab-content').removeClass('active');
	$('.about-item__info.active .about-item__tab-content[data-tab='+tab+']').addClass('active');
});

$('.about-item').on("click",function(e){
	e.preventDefault();
	$('.about-item').removeClass("active");
	$(this).addClass("active");
	let tab=$(this).data('tab');
	$('.about-item__info').removeClass('active');
	$('.about-item__info[data-tab='+tab+']').addClass('active');
});


// function filter(){
			
// 	productcat=get_idarray($('.filter-section_cat input[type="checkbox"]:checked'));		
// 	technic_ids=get_idarray($('.filter-section_tehnic input[type="checkbox"]:checked'));
// 	size_ids=get_idarray($('.filter-section_productsize input[type="checkbox"]:checked'));
// 	years_ids=get_idarray($('.filter-section_productyear input[type="checkbox"]:checked'));
// 	theme_ids=$('.filter-section_productheme input[type="checkbox"]:checked').val();

// 	order_by=$('.filter-section_order input[type="radio"]:checked').val();

	
// 	price_min=$('.filter-section_price input[type="radio"]:checked').data('price_min');
// 	price_max=$('.filter-section_price input[type="radio"]:checked').data('price_max');

// 	if($('input[name=price_min]').length) {
// 		if($('input[name=price_min]').val().length>0) { price_min=$('input[name=price_min]').val(); console.log($('input[name=price_min]').val()); }
// 		if($('input[name=price_max]').val().length>0) { price_max=$('input[name=price_max]').val(); console.log($('input[name=price_max]').val()); }
// 	}
// 	filter_type=$('.filer-data').data('filter_type'); 

// 	data = {
// 		action: 'postfilter',
// 		tags_ids: tags_ids,

// 	} 	

// 	console.log(data);

// 	$.ajax({
// 		url: '/wp-admin/admin-ajax.php',
// 		data: data,
// 		type: 'POST',
// 		beforeSend: function(){				
// 		},  
// 		success: function(data){  
// 			console.log(data);
// 			$('.js-products-results').html(data);					
// 			$('.pagination').addClass('d-none');
// 		}
// 	})


// }

$(window).scroll(function(){
  var aTop = 0;
  var navblock= $('header'); 
  if($(this).scrollTop()>aTop){
    navblock.addClass('fixed');
  } else{
    navblock.removeClass('fixed');
  }
});
// .js-set-law
$('.js-set-law').on("click",function(e){
	e.preventDefault();
	let formtitle = $(this).data("formtitle");
	console.log(formtitle);
	$('.js-form input[name=title]').val(formtitle);
}); 
Fancybox.bind("[data-fancybox]", {
	// Your options go here
});
console.log(Fancybox);
// ;()js-set-law