$(function () {
	let $popup = $('.js-popup');
	let $popupCloseBtn = $('.js-popup-close');
	let lastOpenedPopup = null;
	let scrollPosition;


	$('body').on('click','.js-popup-link',function(e){
		e.preventDefault();
		let $this = $(this);
		let target = $this.attr('data-target');
		let href = $this.attr('data-href');
		let videoSrc = $this.attr('data-src');
		let action = $this.attr('data-action');

		openPopup(target, href, videoSrc, action, $this);

		if (typeof $this.data('scrollto') !== 'undefined' ) {
			setTimeout(function () {
				let form = $('.js-ajax-wrapper').find('form');
				if (form.length) {
					form.find('input:not([type="hidden"])').first().focus();
				}
			}, 1500);
		}

		return false;
	});



	function openPopup(target, href, videoSrc, action, source) {


		if (source.attr('data-action')) {
			$('.js-popup[data-block="'+target+'"]').find('.js-ajax-wrapper').empty();
			ajaxLoadByAction($('.js-popup[data-block="'+target+'"]'),source);
		}

		const $elem = $('.js-popup[data-block="'+target+'"]');
		$popup.removeClass('is-show');
		$elem.addClass('is-show');

		scrollPosition = $(window).scrollTop();

		if (source.attr('data-action')) {
			$('.js-popup[data-block="'+target+'"]').find('.js-ajax-wrapper').empty();
			ajaxLoadByAction($('.js-popup[data-block="'+target+'"]'),source);
		}


	}

	window.openPopup = openPopup;

	$popupCloseBtn.on('click',function(){
		let $this = $(this);
		let $popup = $this.closest('.js-popup');

		$popup.removeClass('is-show');
		$popup.find('.js-iframe').attr('src','');
		// location.hash = '';
		if (scrollPosition !== "undefined") {
			$(window).scrollTop(scrollPosition);
			scrollPosition = undefined;
		}


	});



	function ajaxLoadByAction(target_parent,source) {

		let data={};

		if(source.data('action')=="load_share") {
			data = {
				action: source.data('action'),
				share_id: source.data('share_id'),
			};
		}

		if(source.data('action')=='load_post') {
			data = {
				action: source.data('action'),
				post_id: source.data('post_id'),			
			};
		}

		target_parent.find('.js-ajax-popup-wrapper').empty();
		$.ajax({
			url: '/wp-admin/admin-ajax.php',
			type: "POST",
			data: data,
			success: function(data) {
					target_parent.find('.js-ajax-popup-wrapper').html(data);

			}
		});
	}


});
