import 'jquery-viewport-checker';
import ScrollTrigger from 'gsap/dist/ScrollTrigger.js';

$(function () {

//animation blocks
	function anim() {
	    if($('.js-anim').length > 0){
	      $('.js-anim').viewportChecker({offset: 280});
	    }
	}

  	anim();

  	function anim2() {
	    if($('.js-anim-2').length > 0){
	      $('.js-anim-2').viewportChecker({offset: 100});
	    }
	}

  	anim2();

   gsap.registerPlugin(ScrollTrigger);


//gallery box
	let $gallaryBox = $('.js-gallery-box');

	if ($W.width() > 576 && $gallaryBox.length) {

		let tl = gsap.timeline({
			scrollTrigger: {
			trigger: ".js-gallery",
			start: "top top",
			pin: ".js-gallery",
			end: "+=150%",
			scrub: 1
			}
		});

		tl.to($gallaryBox, 5, {scale: 1, delay: 0.5})

	}

//rotare circle



	$('.js-rotate-trigger').each(function(){

		gsap.to($(this).find(".js-rotate-animation"), {
			rotation: 90,
			duration: 4,
			ease: "none",
			scrollTrigger: {
				trigger: $(this),
				start: "top 25%",
				end: "top -20%",
				scrub: 2,
			}
		})

	});

});
